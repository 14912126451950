import React, {useEffect, useState, useLayoutEffect} from 'react';
import {HebrewCalendar, HDate,  HebrewDateEvent} from '@hebcal/core';

import InfiniteCalendar, {
  Calendar,
  withDateSelection,
  withKeyboardSupport
} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once

import './App.css';

const MARGIN = 40
const USEHEADER = true
const HEIGHTOFFSET = USEHEADER ? (MARGIN * 2) + 98 : (MARGIN * 2)
const HEBCAL_HOLIDAYS_TO_IGNORE = [
  "Erev Purim",
  "Shushan Purim",
  "Erev Pesach",
  "Yom HaShoah",
  "Yom HaZikaron",
  "Pesach Sheni",
  "Lag BaOmer",
  "Yom Yerushalayim",
  "Erev Shavuot",
  "Erev Tish'a B'Av",
  "Tish'a B'Av",
  "Tu B'Av",
  "Leil Selichot",
  "Erev Rosh Hashana",
  "Erev Yom Kippur",
  "Erev Sukkot",
  "Yom HaAliyah",
  // "Rosh Chodesh Tevet",
  "Sigd",
  "Tish'a B'Av (observed)",
  "Purim Katan",
  "Chanukah: 1 Candle",
]

const PJ_HOLIDAY_DATA = {
  "Rosh Chodesh": {
    "pj_name": "Rosh Chodesh",
    "desc": "Rosh Chodesh (New Moon) marks the start of a new month on the Jewish calendar.",
    "link": "https://pjlibrary.org/beyond-books/pjblog/june-2021/what-is-rosh-chodesh",
  },
  "Tu BiShvat": {
    "pj_name": "Tu B’Shevat",
    "additional_desc": "(Birthday of the Trees)",
      "desc": "Tu B’Shevat (Birthday of the Trees), a “Jewish Earth Day” that falls when trees in Israel are beginning to bloom, is marked by planting saplings and eating fruits and nuts. ",
      "link": "https://pjlibrary.org/tubshevat",
    },

  "Purim": {
    "pj_name": "Purim",
    "additional_desc": "(Festival of Esther’s Bravery)",
      "desc": "Purim (Festival of Esther’s Bravery) saving the Jews of ancient Persia from the villainous Haman and is marked by costumes, noisemaking, and gift baskets with special triangular cookies (hamantaschen).",
      "link": "https://pjlibrary.org/purim",
    },

  "Pesach": {
    "pj_name": "Passover",
    "additional_desc": "(Festival of Freedom)",
      "desc": "Passover (Festival of Freedom) is a week-long holiday recalling the Israelites’ exodus from slavery in Egypt, a story Jewish families retell each year at the hands-on Passover seder (ritual meal).",
      "link": "https://pjlibrary.org/passover",
    },

  "Yom HaAtzma'ut": {
    "pj_name": "Yom Ha’atzmaut",
    "additional_desc": "(Israel Independence Day)",
      "desc": "Yom Ha’atzmaut (Israel Independence Day) commemorates the establishment of the modern state of Israel in 1948 and is celebrated with parties featuring Israeli music and food.",
      "link": "https://pjlibrary.org/yomhaatzmaut",
    },

  "Shavuot": {
    "pj_name": "Shavuot",
    "additional_desc": "(Festival of the Giving of the Torah)",
      "desc": "Shavuot (Festival of the Giving of the Torah) recalls the Israelites’ receiving the Ten Commandments at Mount Sinai and is celebrated by studying the Torah (all through the night) and eating cheesecake and blintzes.",
      "link": "https://pjlibrary.org/shavuot",
    },

  "Rosh Hashana": {
    "pj_name": "Rosh Hashanah",
    "additional_desc": "(Jewish New Year)",
      "desc": "Rosh Hashanah (Jewish New Year) is often referred to as the “Birthday of the World.” It’s a time to reflect, sing, pray, gather with loved ones, and of course, eat apples and honey.",
      "link": "https://pjlibrary.org/rosh-hashanah",
    },

  "Yom Kippur": {
    "pj_name": "Yom Kippur",
    "additional_desc": "(Day of Forgiving)",
      "desc": "Yom Kippur (Day of Forgiving) is a fast day focused on apologizing for actions of the past year and thinking about how to improve in the new year.",
      "link": "https://pjlibrary.org/yom-kippur",
    },

  "Sukkot": {
    "pj_name": "Sukkot",
    "additional_desc": "(Festival of Small Huts)",
      "desc": "Sukkot (Festival of Small Huts) is a week-long harvest holiday in which Jewish families build a temporary hut (sukkah) for eating meals and welcoming guests.",
      "link": "https://pjlibrary.org/sukkot",
    },

  "Shmini Atzeret": {
    "pj_name": "Shemini Atzeret",
    "additional_desc": "(Praying for Rain)",
      "desc": "Shemini Atzeret (Praying for Rain) falls on the day after Sukkot and marks the beginning of the rainy season in Israel with a traditional prayer for rain.",
      "link": "https://pjlibrary.org/beyond-books/pjblog/august-2017/holiday-101-shemini-atzeret",
    },

  "Simchat Torah": {
    "pj_name": "Simchat Torah",
    "additional_desc": "(Rejoicing with the Torah)",
      "desc": "Simchat Torah (Rejoicing with the Torah) is a festive day of dancing with Torah scrolls (the first five books of the Bible). After reading the very last part of the Torah, we roll it all the way back to read the beginning.",
      "link": "https://pjlibrary.org/beyond-books/pjblog/august-2020/what-is-simchat-torah",
    },

  "Chanukah": {
    "pj_name": "Hanukkah",
    "additional_desc": "(Festival of Lights)",
      "desc": "Hanukkah (Festival of Lights) is an eight-day holiday that celebrates miracles—both the Maccabees’ victory over Syrian-Greek oppressors and the oil that lasted for eight nights in the Ancient Temple’s menorah.",
      "link": "https://pjlibrary.org/hanukkah",
    },

    "Shabbat": {
      "pj_name": "Shabbat",
      "desc": "Shabbat (the Jewish Sabbath) is a “palace in time,” a day set aside each week (beginning Friday evening) to take a break and have quality time as a family and community.",
      "link": "https://pjlibrary.org/shabbat",
    }

}



const now = new Date();
const minYear = now.getFullYear() - 2
const maxYear = now.getFullYear() + 5



const options = {
  year: minYear,
  isHebrewYear: false,
  candlelighting: false,
  sedrot: false,
  omer: false,
  numYears: maxYear-minYear+1,
  noMinorFast: true,
  noSpecialShabbat: true
};


const getDaysBetweenDates = (start, end, dayName) => {
    let result = [];
    const days = {sun:0,mon:1,tue:2,wed:3,thu:4,fri:5,sat:6};
    const day = days[dayName.toLowerCase().substr(0,3)];
    // Copy start date
    let current = new Date(start);
    // Shift to next of required days
    current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
    // While less than end date, add dates to result array
    while (current < end) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
    return result;
  }

const shabbosim = (getDaysBetweenDates(new Date(minYear,0,1), new Date(maxYear,11,31), 'Sat'));
const holidays = HebrewCalendar.calendar(options);

//console.log(holidays)

function App() {
  // Render the Calendar
  var today = new Date();

  const [calendarHeight, setCalendarHeight] = useState(window.innerHeight - HEIGHTOFFSET)
  const [rowHeight, setRowHeight] = useState(200)
  const [showOverlay, setShowOverlay] = useState(false)
  const [yearNow, setYearNow] = useState(today.getFullYear())
  const [monthNow, setMonthNow] = useState(today.getMonth())
  const [dayNow, setDayNow] = useState(today.getDate())

  const Moon = {
    phases: ['new moon', 'waxing crescent moon', 'quarter moon', 'waxing gibbous moon', 'full moon', 'waning gibbous moon', 'last quarter moon', 'waning crescent moon'],
    emoji: ['🌑', '🌒', '🌓', '🌔', '🌕', '🌖', '🌗', '🌘'],

    phase: function (year, month, day) {
      let b = 0;

      if (month < 3) {
        year--;
        month += 12;
      }

      ++month;
      let c = 365.25 * year;
      let e = 30.6 * month;
      let jd = c + e + day - 694039.09; // jd is total days elapsed
      jd /= 29.5305882; // divide by the moon cycle
      b = parseInt(jd); // int(jd) -> b, take integer part of jd
      jd -= b; // subtract integer part to leave fractional part of original jd
      b = Math.round(jd * 8); // scale fraction from 0-8 and round

      if (b >= 8) b = 0; // 0 and 8 are the same so turn 8 into 0
      return {phase: b, name: Moon.phases[b], emoji: Moon.emoji[b]};
    }
  };

  const ordinal_suffix_of = (i) => {
      var j = i % 10,
          k = i % 100;
      if (j == 1 && k != 11) {
          return i + "st";
      }
      if (j == 2 && k != 12) {
          return i + "nd";
      }
      if (j == 3 && k != 13) {
          return i + "rd";
      }
      return i + "th";
  }

  const onScrollEnd = (scrollTop) => {
    reloadEvents()
    const todayHeader = document.querySelector(".Cal__Today__show")

    const gotoToday = () => {
        let element = document.querySelector(`.Cal__Day__root[data-date=\"${today.getFullYear()}-${(today.getMonth()+1).toString().padStart(2, "0")}-${(today.getDate()).toString().padStart(2, "0")}\"]`)
        element.click()
    }

 // setTimeout(function(){ alert("Hello"); }, 3000);

    if (todayHeader) {
        todayHeader.addEventListener("click", function(){
            setTimeout(function(){
                gotoToday()
            }, 500);
        });
    }
  }

  const applyStickerToDate = (date, stickerType) => {

    const year = date.getFullYear();
    const month = date.getMonth();
    const monthOffset = month + 1;
    const day = date.getDate();


    let element = document.querySelector(`.Cal__Day__root[data-date=\"${year}-${monthOffset.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}\"]`)
    if (element) {
      // let titleToAppend = `<span class="holidayText">${singleEvent["title"]}</span>`

      if (!HEBCAL_HOLIDAYS_TO_IGNORE.includes(stickerType)) {
        let div = document.createElement('div');
        div.className = `sticker ${stickerType.replace(/[ \':]/g,'_')}`;
        let text = document.createTextNode(' ');
        div.appendChild(text);
        element.appendChild(div)
      }

    }

  }

  const reloadEvents = () => {
    document.querySelectorAll('.sticker').forEach(e => e.remove());
    for (const [i, v] of shabbosim.entries()) {
      applyStickerToDate(v, `Shabbat_${(i % 4)+1}`)
    }

    for (const singleEvent of holidays) {
      const date = (singleEvent.getDate().greg())
      applyStickerToDate(date, singleEvent["desc"])

    }
      document.documentElement.style.setProperty('--sticker-height',`${document.querySelector('.Cal__Day__root').offsetWidth}px`);
      document.documentElement.style.setProperty('--sticker-width',`${document.querySelector('.Cal__Day__root').offsetWidth*1.45}px`);
  }

  const dateSelected = (date) => {

    const year = date.getFullYear();
    const month = date.getMonth();
    const monthOffset = month + 1;
    const day = date.getDate();

    const aYearFromDate = new Date(year + 1, month, day);

    const upcomingHolidays = HebrewCalendar.calendar({
      start: date,
      end: aYearFromDate,
      isHebrewYear: false,
      isHebrewYear: false,
      candlelighting: false,
      sedrot: false,
      omer: false,
      noMinorFast: true,
      noSpecialShabbat: true
    });

    const next5Holidays = {}

    for (const calEvent of upcomingHolidays) {
      if (Object.keys(next5Holidays).length >= 5) {
        break;
      }


      if (!HEBCAL_HOLIDAYS_TO_IGNORE.includes(calEvent.desc)) {
        if (!next5Holidays.hasOwnProperty(calEvent.basename()) && !(calEvent.basename().includes("Chodesh")) ) {
          next5Holidays[calEvent.basename()] = calEvent.getDate().greg()
        }
      }
    }



    // Create items array
    let sortedNext5Holidays = Object.keys(next5Holidays). map(function(key) {
      return [key, next5Holidays[key]];
    });

    // Sort the array based on the second element
    sortedNext5Holidays.sort(function(first, second) {
      return first[1] - second[1];
    });

    const moonPhase = Moon.phase(year, month, day)

    const heDateData = new HDate(new Date(year, month, day));

    document.querySelector(`#details`).innerHTML = "";

    let moonHTML = document.createElement('div');
    moonHTML.className = "moonPhase";
    let moonText = document.createTextNode(moonPhase.emoji);
    moonHTML.appendChild(moonText);
    document.querySelector(`#details`).appendChild(moonHTML)

    let dayDetailsHTML = document.createElement('div');
    dayDetailsHTML.className = "dayDetails";

    let dayDetailsContent = document.createElement('p');
    let dayDetailsText = document.createTextNode(`${date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} is the ${ordinal_suffix_of(heDateData.getDate())} of ${heDateData.getMonthName().replace("Iyyar", "Iyar").replace("Sh'vat", "Shevat")}. There is a ${moonPhase.name}.`);
    dayDetailsContent.appendChild(dayDetailsText);

    dayDetailsHTML.appendChild(dayDetailsContent)

    document.querySelector(`#details`).appendChild(dayDetailsHTML)


    let holidayContent = document.createElement('div');
    dayDetailsHTML.appendChild(holidayContent)


    const generateTodayHolidayText = (holidayName) => {
      let link;
      const text = document.createTextNode(`It's ${PJ_HOLIDAY_DATA[holidayName]["pj_name"]}! ${PJ_HOLIDAY_DATA[holidayName]["desc"]} `)
      if (PJ_HOLIDAY_DATA[holidayName]["link"]) {
        link = document.createElement('a');
        link.setAttribute('href', PJ_HOLIDAY_DATA[holidayName]["link"]);
        link.setAttribute('target', "_blank");
        link.appendChild(document.createTextNode("Learn More"));
      }

      return ([text, link])
    }


    for (const [i, v] of sortedNext5Holidays.entries()) {
      const diff =  Math.floor(( Date.parse(v[1]) - Date.parse(date) ) / 86400000);
      if (i > 1 && diff > 30) {break}

      let upcommingDay = document.createElement('p');

      let daysOrDay = "days"

      if (diff == 1) {
        daysOrDay = "day"
      }

      let upcommingDayText = document.createTextNode(`${diff} ${daysOrDay} till ${PJ_HOLIDAY_DATA[v[0]]["pj_name"]}`)

      let newlink;


      if (diff == 0) {
        const newData = generateTodayHolidayText(v[0])
        upcommingDayText = newData[0]
        newlink = newData[1]
      }

      upcommingDay.appendChild(upcommingDayText);
      if (newlink) {upcommingDay.appendChild(newlink)}
      holidayContent.appendChild(upcommingDay)

    }

    if (date.getDay() == 6){
      let upcommingDay = document.createElement('p');

      const newData = generateTodayHolidayText("Shabbat")
      upcommingDay.appendChild(newData[0]);
      if (newData[1]) {upcommingDay.appendChild(newData[1])}
      holidayContent.prepend(upcommingDay)
    }


    if (HebrewCalendar.getHolidaysOnDate(heDateData) && HebrewCalendar.getHolidaysOnDate(heDateData)[0].desc.includes("Rosh Chodesh")) {
      let upcommingDay = document.createElement('p');
      const newData = generateTodayHolidayText("Rosh Chodesh")
      upcommingDay.appendChild(newData[0]);
      if (newData[1]) {upcommingDay.appendChild(newData[1])}
      holidayContent.prepend(upcommingDay)

    }

    if (HebrewCalendar.getHolidaysOnDate(heDateData) && HebrewCalendar.getHolidaysOnDate(heDateData).length > 1 && HebrewCalendar.getHolidaysOnDate(heDateData)[1].desc.includes("Rosh Chodesh")) {
      let upcommingDay = document.createElement('p');
      const newData = generateTodayHolidayText("Rosh Chodesh")
      upcommingDay.appendChild(newData[0]);
      if (newData[1]) {upcommingDay.appendChild(newData[1])}
      holidayContent.prepend(upcommingDay)

    }





    let linksDiv = document.createElement('div');
    linksDiv.className = "links";


    let link1 = document.createElement('span');
    let link1Text = document.createTextNode('About · ');
    link1.onclick = function() { setShowOverlay("about") };
    link1.appendChild(link1Text);

    let link2 = document.createElement('span');
    let link2Text = document.createTextNode('Convert a Date · ');
    link2.onclick = function() {setShowOverlay("convert")};
    link2.appendChild(link2Text);

    let link3 = document.createElement('span');
    let link3Text = document.createTextNode('All Holidays');
    link3.onclick = function() {setShowOverlay("holidays")};
    link3.appendChild(link3Text);

    linksDiv.appendChild(link1)
    linksDiv.appendChild(link2)
    linksDiv.appendChild(link3)

    dayDetailsHTML.appendChild(linksDiv)




  }

  useEffect(
      () => {
        reloadEvents();
        dateSelected(today);
        setRowHeight(document.querySelector('.Cal__Day__root').offsetWidth)
      },
      []
  );

  useLayoutEffect(() => {
    function updateSize() {
      setCalendarHeight(window.innerHeight - HEIGHTOFFSET );
      setRowHeight(document.querySelector('.Cal__Day__root').offsetWidth)
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  const convertDate = () => {
    const month = document.querySelector("#month").value
    const day = document.querySelector("#day").value
    const year = document.querySelector("#year").value
    const afterSunset = document.querySelector("#sunset").checked
    const hebrewDate = new HDate(new Date(year, month, day));

    if (afterSunset) {
      document.querySelector("#hebrewDate").innerText = new HebrewDateEvent(hebrewDate.next()).desc.replace("Iyyar", "Iyar").replace("Sh'vat", "Shevat")
    }

    else {
      document.querySelector("#hebrewDate").innerText = new HebrewDateEvent(hebrewDate).desc.replace("Iyyar", "Iyar").replace("Sh'vat", "Shevat")
    }
  }


  const getHolidaysForYear = (year) => {
    //console.log(year)

  const startDate = new Date(parseInt(year), 0, 1);
  const endDate = new Date(parseInt(year), 11, 31)

  const holidaysForYearUnfiltered = HebrewCalendar.calendar({
    start: startDate,
    end: endDate,
    isHebrewYear: false,
    isHebrewYear: false,
    candlelighting: false,
    sedrot: false,
    omer: false,
    noMinorFast: true,
    noSpecialShabbat: true
  });

  //console.log(holidaysForYearUnfiltered)
  // !HEBCAL_HOLIDAYS_TO_IGNORE.includes(calEvent.desc)

  let holidaysForYearFiltered = {};

  for (const calEvent of holidaysForYearUnfiltered) {

    if (!HEBCAL_HOLIDAYS_TO_IGNORE.includes(calEvent.desc)) {
      if (!holidaysForYearFiltered.hasOwnProperty(calEvent.basename()) && !(calEvent.basename().includes("Chodesh")) ) {
        holidaysForYearFiltered[calEvent.basename()] = calEvent.getDate().prev().greg()
      }
    }
  }

  let holidaysForYearSorted = [];
  for (const singleHoliday in holidaysForYearFiltered) {
    holidaysForYearSorted.push([singleHoliday, holidaysForYearFiltered[singleHoliday]]);
  }

  holidaysForYearSorted.sort(function(a, b) {
      return a[1] - b[1];
  });

  //console.log(holidaysForYearSorted)

  let holidayHTMLString = "<table>";

  for (const entry of holidaysForYearSorted) {
    holidayHTMLString = holidayHTMLString + `<tr><td>${entry[1].toLocaleString('default', { month: 'short' })} ${entry[1].getDate()}</td> <td>${PJ_HOLIDAY_DATA[entry[0]]["pj_name"]} <small>${PJ_HOLIDAY_DATA[entry[0]]["additional_desc"]}</small></td></tr>`

  }
    holidayHTMLString = holidayHTMLString + "</table>"

    return holidayHTMLString


  }


  return(
    <div>
    {showOverlay ? <div className="overlay" onClick={() => {
      const now = new Date();
      setYearNow(now.getFullYear());
      setShowOverlay(false)}
    }>
            {showOverlay == "convert"
              ? <div className="convertOverlay" onClick={(e)=>{e.stopPropagation()}}>

              Convert to Hebrew Date

              <br/>
              <br/>
                <select id="month" name="month" defaultValue={monthNow}>
                  <option value="0">January</option>
                  <option value="1">February</option>
                  <option value="2">March</option>
                  <option value="3">April</option>
                  <option value="4">May</option>
                  <option value="5">June</option>
                  <option value="6">July</option>
                  <option value="7">August</option>
                  <option value="8">September</option>
                  <option value="9">October</option>
                  <option value="10">November</option>
                  <option value="11">December</option>
                </select>

                <input type="text" placeholder="Day" id="day" size="2" maxLength="2" defaultValue={dayNow} />
                <input type="text" placeholder="Year" id="year" size="4" maxLength="4" defaultValue={yearNow} />
                <input type="checkbox" id="sunset" name="sunset" /> <small>After Sunset</small>
                <div id="hebrewDate"></div>
                <button onClick={()=>convertDate()}>Convert</button>


                </div>
              : null
            }


            {showOverlay == "holidays" ?

            <div className="holidayOverlay"  onClick={(e)=>{e.stopPropagation()}}>{yearNow} Holidays

              <div dangerouslySetInnerHTML={{__html: getHolidaysForYear(yearNow)}}></div>

              <div className="nextDate">
              <p><small>The holidays begin at sunset of the day listed</small></p>
              <button onClick={()=>{setYearNow(yearNow - 1)}}>{yearNow - 1}</button> - <button onClick={()=>{setYearNow(yearNow + 1)}}>{yearNow + 1}</button>

              </div>





            </div> :

            null

          }

            {showOverlay == "about"
              ? <div className="aboutOverlay" onClick={(e)=>{e.stopPropagation()}}>

                <p><strong>Welcome to the PJ Library Digital Jewish Calendar</strong></p>

                <p>Here you can</p>

                <ul>
                  <li>Explore the Jewish and Gregorian calendars together.</li>
                  <li>Check out dates for Jewish holidays in the future.</li>
                  <li>Convert any Gregorian date to the Hebrew calendar date, like a future bar or bat mitzvah.</li>
                </ul>

                <p>On this calendar, every holiday and Shabbat icon has a unique shape. It spreads across two Gregorian days, so you’ll remember that the holiday begins at sunset and ends after sundown of the next day.</p>

                <p><a href="https://pjlibrary.org/calendar">Click here</a> to learn more about the Jewish calendar and celebrating holidays.</p>

                <p>Print versions of this calendar (sent to PJ Library families in August 2021) are available for purchase on <a href="https://www.amazon.com/pjlibrary">Amazon</a>. Thank you to <a href="https://hillelsmith.info/">Hillel Smith</a> for the visual design of this calendar.</p>

                </div>
              : null
            }


          </div>
          : null
}
    <div className={showOverlay ? "blur row" : "row"}>
      <div className="mobileHeader"></div>
      <div className="calendarColumn">
        <InfiniteCalendar
          min={new Date(minYear, 0, 1)}
          max={new Date(maxYear, 11, 31)}
          Component={withDateSelection(withKeyboardSupport(Calendar))}
          width={'100%'}
          height={calendarHeight}
          rowHeight={rowHeight}
          selected={today}
          display={"days"}
          onSelect={(date) => dateSelected(date)}
          onScrollEnd={(scrollTop) => onScrollEnd(scrollTop)}
          displayOptions = {{
              showWeekdays: false,
              showHeader: USEHEADER,
              shouldHeaderAnimate: false,
              todayHelperRowOffset: 1,
            }}
          locale={{
            headerFormat: 'dddd, MMMM D ',
          }}

        />
      </div>
      <div id="details" className="details">

      </div>
    </div>
    </div>
  )
}

export default App;
